const applications = [
	{
		id: 'windows',
		name: 'Windows Server 2022',
		icon: 'Finnish',
	},
	{
		id: 'sql',
		name: 'SQL Server 2022',
		icon: 'Magento',
	},
];

export default applications;
