const data = {
	name: 'US East (N. Virginia)',
	icon: 'Arabic',
	versions: [
		{
			name: 'Review later in cart',
			applications: [
				{
					id: 'windows',
					pricings: [				
						{
							id: '40GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-40',
						},
						{
							id: '60GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-60',
						},
						{
							id: '80GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-80',
						},
						{
							id: '160GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/windows-server-160',
						},
					],
				},
				{
					id: 'sql',
					pricings: [				
						{
							id: '40GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-40',
						},
						{
							id: '60GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-60',
						},
						{
							id: '80GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-80',
						},
						{
							id: '160GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/sql-server-160',
						},
					],
				},
			],
		},
	],
};

export default data;