import virginia from './os/North_America/virginia';

const data = {
	title: 'Managed Windows Server',
	seoDescription:
		'Create and manage Windows Server and SQL Server VMs on AWS EC2 with access to predefined resources.',
	helpText:
		'We only manage the latest versions of Windows Server or SQL Server in our Windows AWS plans.',
	oses: [
		{
			name: 'Default Datacenter',
			items: [virginia],
		},
	],
	stepOneTitle: 'Server location',
	stepTwoTitle: 'Pick your application',
	stepThreeTitle: 'Choose a cloud server',
	footerSummary: '{{Size}} — {{App}} — AWS {{OS}}',
	banner: '<span class="news-label">Help? ',
	alertIcon: 'reseller',
	alertTitle: 'Microsoft Windows for Amazon Elastic Compute Cloud (EC2)',
	alertMessage: 'Managed Windows Server 2022—or SQL Server Express 2022—configured for EC2 compute. Connect using Windows Remote Desktop Connection (RDC) client. Run apps built for ASP.NET, Exchange Server, and SharePoint.',
};

export default data;
